import router from '../router'

// 默认函数
const default_func = () => {
  console.log('这里会调用原生方法！')
}

// 监听主进程事件
export const renderer_listen = window.electronAPI?.renderer_listen || default_func

// 聚焦
export const show = window.electronAPI?.show || default_func
// 隐藏
export const hide = window.electronAPI?.hide || default_func
// 退出
export const quit = window.electronAPI?.quit || default_func

// 大小调整
export const set_bounds = window.electronAPI?.set_bounds || default_func
export const get_bounds = window.electronAPI?.get_bounds || default_func
export const workarea = window.electronAPI?.workarea || default_func
export const center = window.electronAPI?.center || default_func
export const maximize = window.electronAPI?.maximize || default_func
export const unmaximize = window.electronAPI?.unmaximize || default_func
export const is_maximized = window.electronAPI?.is_maximized || default_func
export const minimize = window.electronAPI?.minimize || default_func

// 是否是APP
export const is_app = !!window.electronAPI?.get_version
// 版本
export const get_version = window.electronAPI?.get_version || default_func

export const electron_init = () => {
  // 获取版本号
  // store.dispatch(`commonData/get_version`)
  // 监听事件
  renderer_listen(async (event, args, name) => {
    // 传入信息
    if (name === 'info') {
      console.log(args, name)
      // 门诊兼容处理
      // if (args.source.toLowerCase() === "o") {
      //   show()
      //   args.patient_visit_id = args.patient_visit_id.replace(/[-_]/g, '')
      //   store.commit(`commonData/update_mini_screen`, true)
      // } else {
      //   // show()
      //   store.commit(`commonData/update_mini_screen`, false)
      //   // 指令模式 进入，如果是入院的话，electron窗口居中
      //   // store.dispatch(`commonData/set_screen_size`, 'init')
      // }

      // 两次数据一样，不更新页面
      // const current_patient_data = JSON.stringify(args)
      // if (current_patient_data === prev_patient_data && router.app.$route.path === '/case') {
      //   return
      // }
      // prev_patient_data = current_patient_data

      // store.commit(`commonData/update_plugin_data`, args)
      // router.push({ path: '/plugin-login', query: { random_id: create_guid()}})
    }
    else if (name === 'open_report') {
      router.push({ path: '/plugin-login', query: { ...args, redirect: '/desktop-report', source: 'desktop' } })
    }
    else if (name === 'open_knowledge_list') {
      router.push({ path: '/plugin-login', query: { ...args, redirect: '/desktop-knowledge-list', source: 'desktop' } })
    }
    // 打印
    else if (name === 'console') {
      console.log(args)
    }
    // 更新状态
    else if (name === 'updater_status') {
      // console.log(args)
    }
    // 提示
    else if (name === 'notice') {
      Notification.info({title: JSON.stringify(args)})
    }
    // 移动、缩放后
    else if (name === 'move' || name === 'resize') {
      // window_change()
    }
  })
}