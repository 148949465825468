import Vue from 'vue'
import ElementUI from 'element-ui'
import './assets/element-variables.scss'
import './reset.css'
import App from './App'

import router from './router'
import store from './store'
import { get_url_params } from './utils/format'
import { electron_init } from './utils/electron'

let url_params = get_url_params()
// 加载方式
if (url_params._open_source) {
  GLOBAL_CONFIG._open_source = 'iframe'
}

Vue.config.productionTip = false
// 全局样式变量
Vue.prototype.GLOBAL_STYLE = GLOBAL_STYLE

Vue.use(ElementUI)

// electron初始化
electron_init()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

