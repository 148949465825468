<template>
  <div id="app">
  <!-- 头部(只有内嵌在electron内，才会展示) -->
    <div
      class="app_header el_drag"
      v-if="is_app"
    >
      <!-- 标题 -->
      <div class="title">
        <img src="./assets/img/icons/32x32.png" class="logo" />惠检桌面端
      </div>
      <!-- 按钮 -->
      <div class="btn_box el_no_drag">
        <div class="btn" @click="minimize">
          <svg
            t="1703822587120"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1471"
            width="40"
            height="40"
          >
            <path
              d="M98.23 451.003l829.685-1.992 0.154 64-829.685 1.992z"
              fill="#ffffff"
              p-id="1472"
            ></path>
          </svg>
        </div>
        <div v-if="!is_max || mini_screen" class="btn" @click="maximize">
          <svg
            t="1704698893579"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5408"
            width="40"
            height="40"
          >
            <path
              d="M926.45937303 97.54062697v828.2973677H97.54062697V97.54062697h828.91874606m4.97102697-77.6722963h-838.8608c-39.7682157 0-72.07989097 32.31167525-72.07989097 72.07989096v839.48217837c0 39.7682157 32.31167525 72.07989097 72.07989097 72.07989097h839.48217837c39.7682157 0 72.07989097-32.31167525 72.07989096-72.07989097v-838.8608c0-40.38959408-32.31167525-72.70126933-72.70126933-72.70126933 0.62137837 0 0 0 0 0z"
              p-id="5409"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
        <div v-else class="btn" @click="maximize">
          <svg
            t="1703648418410"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4651"
            width="40"
            height="40"
          >
            <path
              d="M812.2 65H351.6c-78.3 0-142.5 61.1-147.7 138.1-77 5.1-138.1 69.4-138.1 147.7v460.6c0 81.6 66.4 148 148 148h460.6c78.3 0 142.5-61.1 147.7-138.1 77-5.1 138.1-69.4 138.1-147.7V213c0-81.6-66.4-148-148-148z m-45.8 746.3c0 50.7-41.3 92-92 92H213.8c-50.7 0-92-41.3-92-92V350.7c0-50.7 41.3-92 92-92h460.6c50.7 0 92 41.3 92 92v460.6z m137.8-137.7c0 47.3-35.8 86.3-81.8 91.4V350.7c0-81.6-66.4-148-148-148H260.2c5.1-45.9 44.2-81.8 91.4-81.8h460.6c50.7 0 92 41.3 92 92v460.7z"
              fill="#ffffff"
              p-id="4652"
            ></path>
          </svg>
        </div>
        <div class="btn btn2" @click="hide">
          <svg
            t="1704698549258"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4209"
            width="40"
            height="40"
          >
            <path
              d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z"
              p-id="4210"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <keep-alive :include="include" :max="max">
      <router-view />
    </keep-alive>
    <div v-if="open_source === 'iframe'" class="iframe_close"  @click="close">
      <img src="./assets/img/close.png" />关闭
    </div>
    <VersonDialog
      v-if="visible"
      :visible.sync="visible"
    />
  </div>
</template>

<script>
import VersonDialog from './components/VersonDialog'
import { mapState, mapActions } from 'vuex'
import { setPersonalData, getPersonalData, getSession} from './utils/session'
import { noLoginArr, pluginArr } from './pages/login/config'
import {
  maximize,
  unmaximize,
  minimize,
  hide,
  is_maximized,
  is_app,
} from "./utils/electron";


export default {
  name: 'App',
  created() {
    if(GLOBAL_CONFIG.page_font_size_show) {
      const dom = document.documentElement
      dom.style.fontSize = GLOBAL_CONFIG.page_font_size + 'vw'
    }
    this.get_license()
  },
  data() {
    let verson_config = JSON.parse(qa_verson_config.verson_config)
    return {
      is_app,
      is_max: false,
      visible:false,
      verson_config,
      open_source: GLOBAL_CONFIG._open_source,
    }
  },
  computed: {
    ...mapState('keepAlive', {
      max: state => state.max,
      include: state => state.include,
    }),
  },
  watch: {
    $route() {
      this.update_hint()
    }
  },
  components: {
    VersonDialog
  },
  methods: {
    getPersonalData,
    setPersonalData,
    ...mapActions('commonData', [
      'get_license'
    ]),
    close () {
      top.window.iframe_close()
    },
    // 提示更新
    update_hint() {
      const authToken = getSession()
      let path = this.$route.path
      if(noLoginArr.includes(path) || pluginArr.includes(path) || !authToken) return
      let save_verson = this.getPersonalData('CurrentVerson')
      let current_verson = this.verson_config.update_verson_no
      if((!save_verson || save_verson !== current_verson)) {
        this.setPersonalData('CurrentVerson',current_verson)
        this.visible = true
      }
    },

    update_is_max() {
      this.is_max = true;
    },

    async maximize() {
      // if (this.mini_screen) {
      //   this.update_mini_screen(false);
      // } else {
      const is_max = await is_maximized();
      is_max ? unmaximize() : maximize();
      this.update_is_max(!is_max);
      // }
    },
    async minimize() {
      // const { path } = this.$route;
      // if (
      //   !this.mini_screen &&
      //   ["o"].includes(this.source) &&
      //   ["/case"].includes(path)
      // ) {
      // this.update_mini_screen(true);
      // } else {
      minimize();
      // }
    },
    hide() {
      hide();
    },
  }
}
</script>

<style>
#app {
  height: 100%;
}
.iframe_close {
  position: absolute;
  right: 35px;
  top: 20px;
  z-index: 99;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  & img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
.app_header {
  height: 40px;
  background: #1d4eff;
  display: flex;
  align-items: center;

  & .title {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #fff;
    & label {
      color: #fff;
      margin-left: 4px;
      &:first-child {
        margin-left: 10px;
      }
    }
  }
  & .logo {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  & .btn_box {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  & .btn {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & .icon {
      width: 14px;
      height: 14px;
    }
    &:hover {
      background: #0434e3;
    }
    &.btn2 {
      &:hover {
        background: rgb(189, 3, 3);
        color: #fff;
      }
    }
  }
}
</style>
